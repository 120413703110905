import React from 'react';

const Extras = () => {
    return (
        <div>
            Em Breve!
        </div>
    );
};

export default Extras;