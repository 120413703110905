import React, { useEffect } from 'react';
import { Box, Typography, Grid, Card, CardActionArea, CardMedia, CardContent, CardActions, Button } from '@mui/material';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

function ShowLightbox({ open, close, slides, chapter }) {
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  const ch = params.get('ch');
  const idx = params.get('idx');

  var index = 0;
  if (ch == chapter && idx) {
    const v = parseInt(idx);
    if (v >= 0 && v < slides.length) {
      index = v;
    }
  }

  useEffect(() => {
    if (open) {
      const params = new URLSearchParams(location.search);
      const slideId = params.get('slide');
      const slideIndex = params.get('idx');
      if (slideId && slideIndex) {
        handleSlideChange({ index: parseInt(slideIndex) });
      }
    }
  }, [open, location.search]);

  const handleSlideChange = (index) => {
    const slideIndex = index.index;
    if (slideIndex >= 0 && slideIndex < slides.length) {
      const id = slides[slideIndex].id;
      const ch = slides[slideIndex].chapter;
      const newUrl = `${location.pathname}?slide=${id}&idx=${slideIndex}&ch=${ch}`;
      window.history.replaceState({ slideIndex }, '', newUrl);
      if (window.gtag) {
        window.gtag('config', 'GA_TRACKING_ID', {
          page_path: newUrl
        });
      }
    } else {
      console.error('Invalid slide index:', slideIndex);
    }
  };

  return (
    <>
      <Lightbox
        carousel={{ finite: true, preload: 2 }}
        open={open}
        close={close}
        slides={slides}
        plugins={[Zoom]}
        index={index}
        on={{ view: handleSlideChange }}
      />
    </>
  );
}

ShowLightbox.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  slides: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    chapter: PropTypes.string.isRequired
  })).isRequired,
  chapter: PropTypes.string.isRequired,
};

function generateImgUrl(img) {
  const baseUrl = 'https://firebasestorage.googleapis.com/v0/b/o-mamaco-consciente.firebasestorage.app/o';
  const queryParams = '?alt=media';
  return `${baseUrl}/${img}${queryParams}`;
}

function ShowCard({ title, description, logo, slides, chapter }) {
  const params = new URLSearchParams(window.location.search);
  const ch = params.get('ch');
  const [open, setOpen] = React.useState(ch == chapter);

  const handleClose = () => {
    setOpen(false);
    window.history.replaceState({}, '', '/');
  };

  return (
    <Card
      sx={{ maxWidth: 345 }}
      onClick={() => setOpen(true)}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="200"
          image={logo}
          alt={title}
          sx={{ objectFit: 'cover', objectPosition: 'top' }}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {description}
          </Typography>
          <ShowLightbox
            open={open}
            close={handleClose}
            slides={slides}
            chapter={chapter}
          />
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" onClick={() => setOpen(true)}>Abrir</Button>
      </CardActions>
    </Card>
  );
}

ShowCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  slides: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string.isRequired
  })).isRequired,
  chapter: PropTypes.string.isRequired,
};

function Volume1() {
  const cap1 = [
    { id: 'c1p1', chapter: 'c1', src: generateImgUrl('c1p1.webp') },
    { id: 'c1p2', chapter: 'c1', src: generateImgUrl('c1p2.webp') },
    { id: 'c1p3', chapter: 'c1', src: generateImgUrl('c1p3.webp') },
    { id: 'c1p4', chapter: 'c1', src: generateImgUrl('c1p4.webp') },
    { id: 'c1p5', chapter: 'c1', src: generateImgUrl('c1p5.webp') },
    { id: 'c1p6', chapter: 'c1', src: generateImgUrl('c1p6.webp') },
    { id: 'c1p7', chapter: 'c1', src: generateImgUrl('c1p7.webp') },
    { id: 'c1p8', chapter: 'c1', src: generateImgUrl('c1p8.webp') },
    { id: 'c1p9', chapter: 'c1', src: generateImgUrl('c1p9.webp') },
    { id: 'c1p10', chapter: 'c1', src: generateImgUrl('c1p10.webp') },
    { id: 'c1p11', chapter: 'c1', src: generateImgUrl('c1p11.webp') },
    { id: 'c1p12', chapter: 'c1', src: generateImgUrl('c1p12.webp') },
    { id: 'c1p13', chapter: 'c1', src: generateImgUrl('c1p13.webp') },
  ];

  const cap2 = [
    { id: 'c2p1', chapter: 'c2', src: generateImgUrl('c2p1.webp') },
    { id: 'c2p2', chapter: 'c2', src: generateImgUrl('c2p2.webp') },
    { id: 'c2p3', chapter: 'c2', src: generateImgUrl('c2p3.webp') },
    { id: 'c2p4', chapter: 'c2', src: generateImgUrl('c2p4.webp') },
    { id: 'c2p5', chapter: 'c2', src: generateImgUrl('c2p5.webp') },
    { id: 'c2p6', chapter: 'c2', src: generateImgUrl('c2p6.webp') },
    { id: 'c2p7', chapter: 'c2', src: generateImgUrl('c2p7.webp') },
    { id: 'c2p8', chapter: 'c2', src: generateImgUrl('c2p8.webp') },
    { id: 'c2p9', chapter: 'c2', src: generateImgUrl('c2p9.webp') },
    { id: 'c2p10', chapter: 'c2', src: generateImgUrl('c2p10.webp') },
    { id: 'c2p11', chapter: 'c2', src: generateImgUrl('c2p11.webp') },
    { id: 'c2p12', chapter: 'c2', src: generateImgUrl('c2p12.webp') },
  ];

  const cap3 = [
    { id: 'c3p1', chapter: 'c3', src: generateImgUrl('c3p1.webp') },
    { id: 'c3p2', chapter: 'c3', src: generateImgUrl('c3p2.webp') },
    { id: 'c3p3', chapter: 'c3', src: generateImgUrl('c3p3.webp') },
    { id: 'c3p4', chapter: 'c3', src: generateImgUrl('c3p4.webp') },
    { id: 'c3p5', chapter: 'c3', src: generateImgUrl('c3p5.webp') },
    { id: 'c3p6', chapter: 'c3', src: generateImgUrl('c3p6.webp') },
    { id: 'c3p7', chapter: 'c3', src: generateImgUrl('c3p7.webp') },
    { id: 'c3p8', chapter: 'c3', src: generateImgUrl('c3p8.webp') },
    { id: 'c3p9', chapter: 'c3', src: generateImgUrl('c3p9.webp') },
    { id: 'c3p10', chapter: 'c3', src: generateImgUrl('c3p10.webp') },
    { id: 'c3p11', chapter: 'c3', src: generateImgUrl('c3p11.webp') },
    { id: 'c3p12', chapter: 'c3', src: generateImgUrl('c3p12.webp') },
  ];

  const cap4 = [
    { id: 'c4p1', chapter: 'c4', src: generateImgUrl('c4p1.webp') },
    { id: 'c4p2', chapter: 'c4', src: generateImgUrl('c4p2.webp') },
    { id: 'c4p3', chapter: 'c4', src: generateImgUrl('c4p3.webp') },
    { id: 'c4p4', chapter: 'c4', src: generateImgUrl('c4p4.webp') },
    { id: 'c4p5', chapter: 'c4', src: generateImgUrl('c4p5.webp') },
    { id: 'c4p6', chapter: 'c4', src: generateImgUrl('c4p6.webp') },
    { id: 'c4p7', chapter: 'c4', src: generateImgUrl('c4p7.webp') },
    { id: 'c4p8', chapter: 'c4', src: generateImgUrl('c4p8.webp') },
    { id: 'c4p9', chapter: 'c4', src: generateImgUrl('c4p9.webp') },
    { id: 'c4p10', chapter: 'c4', src: generateImgUrl('c4p10.webp') },
    { id: 'c4p11', chapter: 'c4', src: generateImgUrl('c4p11.webp') },
    { id: 'c4p12', chapter: 'c4', src: generateImgUrl('c4p12.webp') },
    { id: 'c4p13', chapter: 'c4', src: generateImgUrl('c4p13.webp') },
  ];

  // Prefetch the first image of each carousel
  const imagesToPrefetch = [cap1[0].src, cap2[0].src, cap3[0].src, cap4[0].src];
  imagesToPrefetch.forEach((src) => {
    const img = new Image();
    img.src = src;
  });

  return (
    <Box>
      <Typography variant="h2" component="h1" gutterBottom align="center" sx={{ marginBottom: 0 }}>
        O Mamaco Consciente
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom align="center" sx={{ marginBottom: 2 }}>
        De Miguel Araujo e Bruno Fonseca
      </Typography>
      <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
        <Grid item>
          <ShowCard
            title="Capítulo I: Rotina"
            description="Onde os agiotas precisam lidar com o caso Jeorge Tyler."
            slides={cap1}
            logo={generateImgUrl('c1.jpeg')}
            chapter='c1'
          />
        </Grid>
        <Grid item>
          <ShowCard
            title="Capítulo II: Carl Jabobs"
            description="Apresentamos nosso antagonista, que depois de uma tragédia, inicia seu plano de vingança."
            slides={cap2}
            logo={generateImgUrl('c2.png')}
            chapter='c2'
          />
        </Grid>
        <Grid item>
          <ShowCard
            title="Capítulo III: S.W.A.T."
            description="Os agiotas conseguem dinheiro para financiar sua epopéia contra a presidência."
            slides={cap3}
            logo={generateImgUrl('c3.png')}
            chapter='c3'
          />
        </Grid>
        <Grid item>
          <ShowCard
            title="Capítulo IV: Nova York não é o que você pensa"
            description="Na cidade grande, os agiotas, Dave e Mike, encontram seu nêmesis."
            slides={cap4}
            logo={generateImgUrl('c4.jpg')}
            chapter='c4'
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Volume1;
