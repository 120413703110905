import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { logEvent } from 'firebase/analytics';
import { useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messageSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const theme = createTheme({
  // Customize your theme here
});

function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
      page_title: document.title,
      page_location: window.location.href,
      page_domain: window.location.hostname,
    });
  }, [location]);
}

function Border({ Component }) {
    usePageViews();
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const navigate = useNavigate();
    
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            edge="start"
                            sx={{ mr: 2, ...(drawerOpen && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            O Mamaco Consciente
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={drawerOpen}
                    onClose={toggleDrawer}
                    ModalProps={{
                        keepMounted: true,
                        onBackdropClick: toggleDrawer,
                    }}
                >
                    <List component="nav" aria-label="main mailbox folders" sx={{ marginTop: '64px' }}>
                        <ListItem button sx={{ cursor: 'pointer' }} onClick={() => { navigate('/vol1'); toggleDrawer(); }}>
                            <ListItemText primary="Volume I" />
                        </ListItem>
                        <ListItem button sx={{ cursor: 'pointer' }} onClick={() => { navigate('/extras'); toggleDrawer(); }}>
                            <ListItemText primary="Extras" />
                        </ListItem>
                    </List>
                </Drawer>
                <Toolbar /> {/* This Toolbar component is added to create space below the AppBar */}
                <Component />
            </Container>
        </ThemeProvider>
    );
}
Border.propTypes = {
    Component: PropTypes.elementType.isRequired,
};

export default Border;